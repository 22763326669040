import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'
import { AuthService } from "@/service/AuthService";
import { HotelService } from "@/service/HotelService";
import { useSiteStore } from './siteStore';
import { useFavoriteStore } from "./favoriteStore";
import {useRouter, useRoute} from "vue-router";

export const useHotelStore = defineStore('hotel', () => {
    const siteStore = useSiteStore();

    // 0. 日曆預設「入住日」+「退房日」
    const today = ref(formatDate(new Date)); // 初始化「今天」,轉成2024-MM-DD
    const tomorrow = ref(formatDate(new Date(Date.now() + 86400000))) // 初始化「明天」, 2024-MM-DD

    const hotelSearch = reactive({
        destination: "",
        searchType: "",
        date: "",
        mealOption: "",
        rooms: [
            { adult: 1, childWithBed: 0, childWithoutBed: 0 }
        ],
        dateRange: today.value + ' ~ ' + tomorrow.value,
        dateStart: today.value, // 第一次進頁面先抓「今天」, 選VDatePicker後, 就吃VDatePicker傳來的值
        dateEnd: tomorrow.value, // 第一次進頁面先抓「明天」, 選VDatePicker後, 就吃VDatePicker傳來的值
        adults: 1,
        room: 1,
        kid_1to3: 0,
        kid_4to6: 0,
        babyWithBoth: 0,
        babyWithMeal: 0,
        babyWithBed: 0,
        babyNoBoth: 0,
        stayNights: 0,
    });

    const recommandHotel = computed(() => {
        return HotelService.getRecommandHotel(siteStore.settings.displayLanguages);
    })
        
    // add new room
    function addRoom () {
        hotelSearch.rooms.push({ adult: 1, childWithBed: 0, childWithoutBed: 0 });
    }
    // remove last room
    function removeRoom () {
        if (hotelSearch.rooms.length > 1){
            hotelSearch.rooms.pop();
        }
    }

    // 新增成人數量
    function addAdult (roomIndex) {
        hotelSearch.rooms[roomIndex].adult += 1;
    }

    // 移除成人數量
    function removeAdult (roomIndex) {
        if (hotelSearch.rooms[roomIndex].adult > 1){
            hotelSearch.rooms[roomIndex].adult -= 1;
        }
    }

    // 新增小孩帶床數量
    function addChildWithBed (roomIndex) {
        hotelSearch.rooms[roomIndex].childWithBed += 1;
    }

    // 移除小孩帶床數量
    function removeChildWithBed (roomIndex) {
        if (hotelSearch.rooms[roomIndex].childWithBed > 0){
            hotelSearch.rooms[roomIndex].childWithBed -= 1;
        }
    }

    // 新增小孩不帶床數量
    function addChildWithoutBed (roomIndex) {
        hotelSearch.rooms[roomIndex].childWithoutBed += 1;
    }

    // 移除小孩不帶床數量
    function removeChildWithoutBed (roomIndex) {
        if (hotelSearch.rooms[roomIndex].childWithoutBed > 0){
            hotelSearch.rooms[roomIndex].childWithoutBed -= 1;
        }
    }

    // ================= Kory的Pinia =================
    const router = useRouter();
    const route = useRoute();
    const langStorage = ref(null); // localStorage 語系
    const filteredAllHotels = ref([]); // 給 HotelLists.vue, 用來渲染關鍵字匹配之飯店
    const isNoHotel = ref(false); //「找不到搜尋結果」之FLAG
    const skeletonLoading = ref(false); //「SKELETON 讀取」之FLAG

    // 1. 給 HotelLists.vue 渲染用(透過 route 傳filteredHotels.value, 做陣列物件渲染)
    
    
    // 2. 增減成人
    const addAdults = () => hotelSearch.adults +=1;
    const minusAdults = () => {
      if(hotelSearch.adults > 1) hotelSearch.adults -=1; 
    };

    // 3. 增減房間
    const addRooms = () => hotelSearch.room +=1;
    const minusRooms = () => {
      if(hotelSearch.room > 1) hotelSearch.room -=1; 
    };

    // 4.增減小學生
    const addKids_1to3 = () => hotelSearch.kid_1to3 +=1;
    const addKids_4to6 = () => hotelSearch.kid_4to6 +=1;
    const minusKids_1to3 = () => {
      if(hotelSearch.kid_1to3 > 0) hotelSearch.kid_1to3 -=1;
    };
    const minusKids_4to6 = () => {
      if(hotelSearch.kid_4to6 > 0) hotelSearch.kid_4to6 -=1;
    };

    // 5. 增減嬰兒
    const addBabyBoth = () => hotelSearch.babyWithBoth +=1;
    const addBabyMeal = () => hotelSearch.babyWithMeal +=1;
    const addBabyBed = () => hotelSearch.babyWithBed +=1;
    const addBabyNoBoth = () => hotelSearch.babyNoBoth +=1;
    const minusBabyBoth = () => {
      if(hotelSearch.babyWithBoth >0) hotelSearch.babyWithBoth -=1;
    };
    const minusBabyMeal = () => {
      if(hotelSearch.babyWithMeal >0) hotelSearch.babyWithMeal -=1;
    };
    const minusBabyBed = () => {
      if(hotelSearch.babyWithBed >0) hotelSearch.babyWithBed -=1;
    };
    const minusBabyNoBoth = () => {
      if(hotelSearch.babyNoBoth >0) hotelSearch.babyNoBoth -=1;
    };

    // 6. 小朋友統計人數
    const totalKids = computed(() => {
      return hotelSearch.kid_1to3 + hotelSearch.kid_4to6 + hotelSearch.babyWithBoth
      + hotelSearch.babyWithMeal + hotelSearch.babyWithBed + hotelSearch.babyNoBoth
    });

    // 11. 給每個飯店, 增加 loveFlag:false
    function giveLoveFlag (hotels) {
      return hotels.map((hotel) => ({
        ...hotel,
        loveFlag: false
      }))
    }

    // 12. 重新API搜尋時, 再次檢查 loveFlag:true/false
    const favoriteStore = useFavoriteStore();

    // 13. 日期格式化
    function formatDate(date) {
      // var day_list = translate("weekdays").split(",");
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      // return [year, month, day].join("-") + `(${day_list[d.getDay()]})`;
      return [year, month, day].join("-"); // 去除(Fri)(Sat)字眼
    }

    // 14. 更新日期(若上次CheckIn晚於今日, 如昨天選0731, 那今天要自動切成0801)
    const updateDates = (newCheckIn, newCheckout) => {
      hotelSearch.dateStart = newCheckIn;
      hotelSearch.dateEnd = newCheckout;
      hotelSearch.dateRange = newCheckIn + '-' + newCheckout
    }

    // 15. 渲染「找不到」飯店
    const checkEmptyHotel = () => {
      if(filteredAllHotels.value.HotelList.length === 0 || !filteredAllHotels.value) {
        isNoHotel.value = true;
      }
    }

    // 16. 進階搜尋參數, 必須緩存Persist, router.afterEach會用到
    const advancedParams = ref({
      smoking: null,
      bedType: null,
      facilityArr: [],
    });

    // 17. 進階搜尋 SEE MORE 開關, 若被打開, 點進階搜尋, 必需仍是打開, 因此寫在Pinia, 也就是要吃全域的狀態
    const showAllBeds = ref(false);


    return { 
        hotelSearch, 
        recommandHotel, 
        filteredAllHotels,
        totalKids,
        today,
        tomorrow,
        isNoHotel,
        skeletonLoading,
        advancedParams,
        showAllBeds,
        addRoom, 
        removeRoom, 
        addAdult, 
        removeAdult, 
        addChildWithBed, 
        removeChildWithBed, 
        addChildWithoutBed, 
        removeChildWithoutBed, 
        addAdults,
        minusAdults,
        addRooms,
        minusRooms,
        addKids_1to3,
        addKids_4to6,
        minusKids_1to3,
        minusKids_4to6,
        addBabyBoth,
        addBabyMeal,
        addBabyBed,
        addBabyNoBoth,
        minusBabyBoth,
        minusBabyMeal,
        minusBabyBed,
        minusBabyNoBoth,
        // updateFavorite,
        giveLoveFlag,
        updateDates,
        checkEmptyHotel,
      }

}

)
